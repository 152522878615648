import { merge } from 'lodash';
import StepLabel from './StepLabel';
import { Theme } from '@mui/material';

const ComponentsOverrides = (theme: Theme) => {
  return merge(
    StepLabel(theme)
  )
}

export default  ComponentsOverrides