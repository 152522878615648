import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";
import { message } from "../../utils/message";
import { checkFailure, getStatus } from "../../utils/status";
import { PaymentContext } from "./PaymentStatus";


const Message = () => {
  const { paymentStatus } = useContext(PaymentContext);
  const { reason_failed } = useContext(CheckoutContext);
  const matches = useMediaQuery("(max-width:367px)");

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          color: checkFailure(paymentStatus) ? "error.main" : "success.main",
          textAlign: "center",
          width: {
            md: "610px",
          },
          height: {
            md: "200px",
          },
          p: 4,
          px: matches ? 1.2 : 4,
          background: checkFailure(paymentStatus) ? "#FFF0F0" : "#F4FFF0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {checkFailure(paymentStatus) ? (
          <>
            <Typography variant="h4" component="h4">
              {" "}
              Payment Failed{" "}
            </Typography>
            <Typography variant="h5">{reason_failed}</Typography>
          </>
        ) : paymentStatus === "NOTIFICATION_FAILED" ? (
          <>
            <Typography variant="h4" component="h4">
              {" "}
              Payment Failed, Please contact support!
            </Typography>
          </>
        ) : (
          <Typography variant="h4" component="h4">
            Payment Successful{" "}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default Message;
