import React, { FC } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import axios from "axios";
import { BASE_URL, VERSION } from "./utils/config";
import Checkout from './pages/Checkout'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

axios.defaults.baseURL = `${BASE_URL}/${VERSION}`;

const queryClient = new QueryClient();

const App : FC = () =>{

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path='/checkout/:checkoutId' element={<Checkout/>}/>
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
