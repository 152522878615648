import React, { useContext } from 'react'
import {Stepper, Step, StepLabel,Typography, useMediaQuery} from '@mui/material/'
import ColorlibConnector from './ColorlibConnector';
import { PaymentContext } from '../PaymentStatus/PaymentStatus';
import ColorlibStepIcon from './ColorlibStepIcon';
import { checkFailure } from '../../utils/status';

const steps = ['Payment Setup', 'Pending Payment', 'Payment Verification'];

const PaymentStepper = () => {
  const { paymentStatus, activeStep } = useContext(PaymentContext)
  const matchesXs = useMediaQuery('(max-width:480px)')
  
  const isStepFailed = (step: number) => {
    if (checkFailure(paymentStatus)) {
      return step === activeStep-1;
    }
  };

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
      {steps.map((label, index) => {
        const [label1, label2]= label.split(' ')
        const labelProps: {
          optional?: React.ReactNode;
          error?: boolean;
        } = {};

        if (isStepFailed(index)) {
          labelProps.optional = (
            <Typography variant='h5' color="error" sx={{ textAlign: "center"}}>
              Error
            </Typography>
          );
          labelProps.error = true;
        }
        
        const newLable= <Typography variant='h5'>{label1} <br />  {label2}</Typography>

        return (
          <Step key={label} >
            <StepLabel {...labelProps}  StepIconComponent={ColorlibStepIcon}>
              {matchesXs? newLable: label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  )
}

export default PaymentStepper