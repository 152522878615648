import { FC, useContext } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { CheckoutContext } from "../../pages/Checkout";
import { validateURL } from "../../utils/validateURL";

const LoadingMessage: FC = () => {
  const { branchEmail, branchPhone, recurring_payment } =
    useContext(CheckoutContext);

  const handleEmail = (): string => {
    return branchEmail.includes("@")
      ? `mailto:${branchEmail}`
      : validateURL(branchEmail)
      ? branchEmail
      : "";
  };

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      <CircularProgress />
      {recurring_payment ? (
        <Typography variant="h6" mt={2}>
          Your payment on process, it may take a while <br />
          Please wait for 10 minutes and try agin or
          <br />
          Please contact support
        </Typography>
      ) : (
        <Typography variant="h6" mt={2}>
          For any support please contact us
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {branchPhone && (
          <MuiLink
            href={`tel:${branchPhone}`}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <PhoneIcon sx={{ color: "primary.main", fontSize: "1.5rem" }} />
            <Typography variant="body1" ml={2} my={1} sx={{ color: "#000" }}>
              {branchPhone}
            </Typography>
          </MuiLink>
        )}
        {branchEmail && (
          <MuiLink
            href={handleEmail()}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmailIcon sx={{ color: "primary.main", fontSize: "1.5rem" }} />
            <Typography variant="body1" ml={2} sx={{ color: "#000" }}>
              {branchEmail}
            </Typography>
          </MuiLink>
        )}
      </Box>
    </Grid>
  );
};

export default LoadingMessage;
