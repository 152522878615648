import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

 const GlobalStyles = () => {

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        a: {
          textDecoration: "none",
          color: "#000"
        },
        ul: {
          listStyle: 'none'
        }
      }}
    />
  );
}

export default GlobalStyles