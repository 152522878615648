import axios from 'axios'
import { BASE_URL } from '../utils/config'

// Get timeout configuration and set intervalMs and maximum waiting time
export const getTimeoutConfig = async () =>{
    try {
        const {data} = await axios.get(`${BASE_URL}/v1/system_configurations/timeout_configurations?filter=[{"columnField":"name", "operatorValue": "=", "value":"PaymentIntentRefech"}]`)
        return data.data[0]
    } catch (error) {
        // console.log(error)
    }
}