import {
  Avatar,
  Box,
  Grid,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Theme } from "@mui/material/styles";

import PaySubscribe from "./PaySubscribe";
import { CheckoutContext } from "../../pages/Checkout";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import { validateURL } from "../../utils/validateURL";
import { PaymentContext } from "../PaymentStatus/PaymentStatus";
import { useParams } from "react-router-dom";
import { UserSubscriptions } from "../../utils/types";
import AccountInputForm from "../PayWithBankAccount/AccountInputForm";
import { phoneRegex } from "../../utils/validatePhone";
// import {testImage} from '../../../public/assets/sewasew_music_streaming_ethio_telecom_black_logo.svg'

const PaymentForm = ({
  userSubscriptions,
}: {
  userSubscriptions: UserSubscriptions;
}) => {
  const [payMethod, setPayMethod] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const {
    payment_id,
    customerPhone,
    recurring_payment,
    method: payIntentMethod,
    setSelectedMethodLogo,
  } = useContext(CheckoutContext);
  const { activeStep } = useContext(PaymentContext);
  const { checkoutId } = useParams();

  const [paymentMethodId, setPaymentIntentId] = useState("");
  //tobe removed
  const [toast, setToast] = useState(false);

  const allowedPlan = ["gbil_swmu_1d", "gbil_swmu_1w"];

  const { data, isSuccess } = useQuery({
    queryKey: ["getPaymentMethods"],
    queryFn: () => {
      return axios.get("/payment_methods", {
        params: {
          filter: recurring_payment
            ? JSON.stringify([
                {
                  columnField: "recurring_payments",
                  operatorValue: "=",
                  value: "true",
                },
              ])
            : JSON.stringify([
                {
                  columnField: "recurring_payments",
                  operatorValue: "=",
                  value: "false",
                },
              ]),
        },
      });
    },
    enabled: !!checkoutId && Boolean(activeStep === 1),
  });

  const mutation = useMutation(
    (method: { payment_id: string; method_name: string }) =>
      axios.post("/customers/accounts/registration", method),
    {
      onSuccess: (data) => {
        // console.log(data)
      },
      onError: (error) => {
        // console.log(error)
      },
    }
  );

  useEffect(() => {
    if (recurring_payment && payIntentMethod !== "") {
      setPayMethod(payIntentMethod);
    }
  }, [payIntentMethod, recurring_payment]);
  useEffect(() => {
    if (data?.data?.data) {
      phoneRegex.test(customerPhone)
        ? setPaymentMethods(
            data?.data?.data?.filter(
              (method: { name: string }) => method.name !== "telebirr"
            )
          )
        : setPaymentMethods(
            data?.data?.data?.filter(
              (method: { name: string }) => method.name !== "mpesa"
            )
          );
    }
  }, [customerPhone, data?.data?.data]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setPayMethod(nextView);
  };

  const handleRegstration = (method_name: string, registration_url: string) => {
    if (validateURL(registration_url)) {
      mutation.mutate({ payment_id, method_name });
      window.location.href = registration_url;
    }
  };
  const checkStringIncludes = (): boolean => {
    return payMethod.includes("amharabank") || payMethod.includes("zemenbank");
  };
  return (
    <>
      {isSuccess &&
        (checkStringIncludes() ? (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AccountInputForm
              method={payMethod}
              paymentMethodId={paymentMethodId}
              setMethod={setPayMethod}
            />
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" sx={{ color: "common.black", mb: "1rem" }}>
              Choose Payment Method
            </Typography>
            <ToggleButtonGroup
              orientation="vertical"
              value={payMethod}
              exclusive
              onChange={handleChange}
              aria-label="Payment Method"
            >
              {isSuccess &&
                paymentMethods
                  .filter((method: any) => {
                    if (recurring_payment) {
                      if (allowedPlan.includes(userSubscriptions?.plan_id)) {
                        // return method;
                        return method.name.toLocaleLowerCase() === "airtime";
                      }
                      return method.name.toLocaleLowerCase() !== "airtime";
                    }
                    if (payIntentMethod) {
                      const label = method?.label
                        ?.toString()
                        .toLocaleLowerCase()
                        .split(" ")
                        .join("");
                      return label === payIntentMethod;
                    }
                    return method;
                  })
                  .map((method: any) => {
                    return (
                      <ToggleButton
                        sx={{
                          color: (theme: Theme) => theme.palette.common.black,
                          outlineColor: (theme: Theme) =>
                            theme.palette.grey[300],
                          outlineWidth: "1px",
                          outlineStyle: "solid",
                          mb: "3rem",
                          "&.Mui-selected, &.Mui-selected:hover": {
                            // outlineColor: (theme: Theme) =>
                            //   theme.palette.primary.main,
                            outlineColor: "#FFAA00",
                            backgroundColor: "#f5f5f5",
                            outlineWidth: "2px",
                            color: (theme: Theme) => theme.palette.primary.main,
                          },
                          // width:" 440px",
                          height: " 76px",
                          // transform: "matrix(0.02, -1, 1, 0.02, 0, 0)",
                          background: "#F5F5F5",
                          // border: "1px solid #FFAA00",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          textTransform: "none",
                          // position: 'absolute',
                        }}
                        key={method.id}
                        value={method.name}
                        onClick={() => {
                          setPaymentIntentId(method.id);
                          setSelectedMethodLogo(method.logo ?? undefined);
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-around"
                          ml={-0.5}
                        >
                          <Box
                            sx={{
                              width: "6.88rem",
                              height: "7.2rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              zIndex: "999",
                              ml: { xs: 0, sm: 3 },
                              background: `url('/assets/curvedImage.png')`,
                              alignSelf: "center",
                            }}
                          >
                            <Avatar
                              variant="square"
                              src={method.logo}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                ml: 1.5,
                                width: 55,
                                height: 55,
                                alignSelf: "center",
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              ml: { xs: 0, md: "2rem" },
                              color: "common.black",
                              fontSize: { sm: 14, md: 16, lg: 18 },
                            }}
                          >
                            {method.label}
                          </Typography>
                        </Box>
                        {/* <Link 
                      href={method?.registration_url} 
                      sx={{textDecoration: "none"}}
                    > */}
                        {(method.registration_url ||
                          method.name.toLocaleLowerCase() === "airtime") && (
                          <Link
                            href={method?.registration_url}
                            referrerPolicy="unsafe-url"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRegstration(
                                method?.name,
                                method?.registration_url
                              );
                            }}
                          >
                            <Box
                              sx={{
                                width: "6rem",
                                height: "3.5rem",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                color: "common.black",
                                boxShadow: "3px 3px 4px #0000001A",
                                ":active": {
                                  border: "1px solid #FFAA00",
                                },
                              }}
                            >
                              {method.name.toLocaleLowerCase() === "airtime" ? (
                                <MobileFriendlyOutlinedIcon
                                  sx={{
                                    fontSize: { xs: "1rem", sm: "1.2rem" },
                                  }}
                                />
                              ) : (
                                <AddCircleOutlineOutlinedIcon
                                  sx={{
                                    fontSize: { xs: "1rem", sm: "1.2rem" },
                                  }}
                                />
                              )}
                              <Typography variant="caption">
                                {method.name === "airtime"
                                  ? "Mobile Only"
                                  : "Open Account"}
                              </Typography>
                            </Box>
                          </Link>
                        )}
                        {/* </Link> */}
                      </ToggleButton>
                    );
                  })}
            </ToggleButtonGroup>

            {/* {toast && <Typography variant='h6' mb={2}>Please try again later. We are working on it.</Typography>} */}

            <PaySubscribe
              payMethod={payMethod}
              setToast={setToast}
              paymentMethodId={paymentMethodId}
            />
          </Grid>
        ))}
    </>
  );
};

export default PaymentForm;
