export const getStatus = (status_code: string): string => {
    if (status_code) {
        let status: string = ""
        switch (status_code) {
            case "SUBPC":
            case "WPPC":
                status = "PAYMENT_INITILIZED"
                break
            case "SUBPE":
                status = "PAYMENT_EXPIRED"
                break
            case "SUBPOP":
            case "WPIP":
                status = "PAYMENT_PENDING"
                break
            case "SUBPS":
            case "WPS":
                status = "PAYMENT_SUCCESS"
                break
            case "SUBPF":
            case "WPF":
                status = "PAYMENT_FAILED"
                break
            case "SUBNS":
            case 'WPNS':
                status = "NOTIFICATION_SUCCESS"
                break
            case "SUBNF":
            case "WPNF":
                status = "NOTIFICATION_FAILED"
                break
        }

        return status
    }
    return status_code
}

export const checkFailure = (paymentStatus: string) => {
    return ['PAYMENT_FAILED','PAYMENT_EXPIRED', 'NOTIFICATION_FAILED'].includes(paymentStatus)
}