import { Theme } from '@mui/material/styles';

import { pxToRem, responsiveFontSizes } from '../typography'

const StepLabel = (theme: Theme) => {
  return {
      MuiStepLabel:{
          styleOverrides:{
              label:{
                    fontWeight: 500,
                    lineHeight: 1.5,
                    fontSize: pxToRem(14),
                     ...responsiveFontSizes({ sm: 14, md: 20, lg: 24 }),
                     
                     '&.MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                     {
                         color: 'primary.main', 
                         fontWeight: 700
                     },  
                },
                iconContainer:{
                    fontSize: '5rem'
                }
            },      
      },
     MuiSvgIcon:{
         styleOverrides:{
             root: {
                fontSize: '2rem'
             }
         }
     }

  }
}

export default StepLabel
