import { Grid, useMediaQuery } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { FC, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { UserSubscriptions } from '../../utils/types'
import { PaymentContext } from '../PaymentStatus/PaymentStatus'
import PaymentDetails from './PaymentDetails'
import PaymentForm from './PaymentForm'
import { CheckoutContext } from '../../pages/Checkout'

const Checkout :FC = () => {
  const matchesXl = useMediaQuery('(min-width:1200px)')

  const {checkoutId} = useParams()
  const {activeStep} = useContext(PaymentContext)
  const {recurring_payment} = useContext(CheckoutContext)


  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    ["getUserSubscriptions", checkoutId],
    () => {
      return axios.get(`/user_subscriptions/${checkoutId}`)},
      {
        enabled: !!checkoutId && Boolean(activeStep === 1) && recurring_payment
      }
  );

 let userSubscriptions! : UserSubscriptions;

  if(isSuccess && data){
    userSubscriptions = data?.data?.data?.subscription;
  }
  
  return (
    <Grid container  
      columnSpacing={matchesXl ? 6: 0}
      rowSpacing={matchesXl ? 0: 6}
      sx={{
        mt: {
          xs: 0,
          lg: '1.5rem',
        }, 
        px:{xs: 0, sm: 4, md: 6, lg: 8}
      }}  
    >
      <Grid item xs={12} lg={6}>
        <PaymentDetails isSuccess={isSuccess} userSubscriptions={userSubscriptions}/>
      </Grid>
      <Grid item xs={12} lg={6} >
        <PaymentForm userSubscriptions={userSubscriptions}/>
      </Grid>
    </Grid>
  )
}

export default Checkout