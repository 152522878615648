import { FC } from 'react'
import PendingIcon from '@mui/icons-material/Pending';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorIcon from "@mui/icons-material/Error";
import { StepIconProps } from '@mui/material/StepIcon';
import ColorlibStepIconRoot from './ColorlibStepIconRoot';

const  ColorlibStepIcon : FC<StepIconProps> = (props) => {
    const { active, completed, error, className } = props;
  
    const icons: { [index: string]: React.ReactElement } = {
      1: <PaymentIcon />,
      2: <PendingIcon />,
      3: <CheckOutlinedIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active, error }} className={className}>
        { error ? 
          ( <ErrorIcon /> ): 
          ( icons[String(props.icon)]) 
        }
      </ColorlibStepIconRoot>
    );
  }

export default ColorlibStepIcon