import { useEffect, useState, createContext, useContext } from "react";
import { Paper, Grid, useMediaQuery } from "@mui/material/";
import { checkFailure, getStatus } from "../../utils/status";
import PaymentMessage from "./PaymentMessage";
import PaymentStepper from "../PaymentStepper/PaymentStepper";
import { CheckoutContext } from "../../pages/Checkout";
import Checkout from "../Checkout/Checkout";

interface PayStatus {
  paymentStatus: string;
  activeStep: number;
  setActiveStep: any;
}

const payStatus: PayStatus = {
  paymentStatus: "",
  activeStep: 0,
  setActiveStep: null,
};

export const PaymentContext = createContext(payStatus);

const PaymentStatus = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [paymentStatus, setPaymentStatus] = useState("");

  const { status_code, recurring_payment } = useContext(CheckoutContext);

  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    switch (getStatus(status_code)) {
      case "PAYMENT_INITILIZED":
      case "PAYMENT_EXPIRED":
        setActiveStep(1);
        break;
      case "PAYMENT_PENDING":
      case "PAYMENT_SUCCESS":
      case "PAYMENT_FAILED":
        setActiveStep(2);
        break;
      case "NOTIFICATION_SUCCESS":
      case "NOTIFICATION_FAILED":
        setActiveStep(3);
        break;
    }
    setPaymentStatus(getStatus(status_code));
  }, [status_code, recurring_payment]);

  return (
    <PaymentContext.Provider
      value={{ paymentStatus, activeStep, setActiveStep }}
    >
      <Grid container sx={{ minHeight: "100vh", maxWidth: "100vw" }}>
        <Paper
          elevation={3}
          sx={{
            mx: { xs: 1, sm: 4, md: 8, lg: 12 },
            my: { xs: 1, sm: 2, md: 4 },
            p: { xs: 2, sm: 2, md: 4 },
            borderRadius: "8px",
          }}
        >
          <Grid
            container
            spacing={matches ? 2 : 1}
            sx={{ display: "flex", direction: "column", height: "inherit" }}
          >
            <Grid
              item
              xs={12}
              sx={{ height: { xs: "10%", sm: "15%", lg: "20%" } }}
            >
              <PaymentStepper />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "start", sm: "start" },
                alignItems: "center",
                height: { xs: "90%", sm: "85%", lg: "80%" },
              }}
            >
              {!recurring_payment && activeStep === 1 && <Checkout />}
              {!recurring_payment && activeStep >= 2 && <PaymentMessage />}
              {recurring_payment ? (
                checkFailure(paymentStatus) || activeStep >= 2 ? (
                  <PaymentMessage />
                ) : (
                  <Checkout />
                )
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </PaymentContext.Provider>
  );
};

export default PaymentStatus;
