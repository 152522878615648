import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PayMethod } from "../../utils/types";
import { CheckoutContext } from "../../pages/Checkout";
import { validateURL } from "../../utils/validateURL";
import { PaymentContext } from "../PaymentStatus/PaymentStatus";

const PaySubscribe: FC<PayMethod> = ({
  payMethod,
  accountNumber,
  paymentMethodId,
  setToast,
}) => {
  const { payment_id, recurring_payment, customerPhone, method } =
    useContext(CheckoutContext);
  const { activeStep } = useContext(PaymentContext);

  // const subscribe = useRef(false)
  const [subscribe, setSubscribe] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const queryClient = useQueryClient();

  // Tobe Removed
  // const phone = ["251943221789", "251912050972", "251911259134", "251911254532",
  // "251932284373", "251900472370", "251975146165", "251943321789", "251911235523",
  // "251975128309","251911508525", "251911232326", "251911522547", "251911508707",
  // "251911509728", "251944781248", "251904319034", "251937692216", "251907612667", "251973114666",]
  ////

  const { isLoading, isError, data, error, refetch, isSuccess } = useQuery(
    ["getWebPay", payment_id, payMethod],
    () => {
      const device = ["Android", "iPhone"].some((divice) =>
        window.navigator.userAgent.includes(divice)
      )
        ? "mobile"
        : "";
      const webPay = recurring_payment
        ? `/user_subscriptions/${payment_id}/checkout/${payMethod}/web`
        : `/web_pay/${payment_id}/${payMethod}?device=${device}`;
      return axios.post(webPay, {
        account_number: accountNumber,
        payment_method_id: paymentMethodId,
      });
    },
    {
      enabled:
        !!payment_id && !!payMethod && subscribe && Boolean(activeStep === 1),
      onSettled: (data, error) => {
        const toPayUrl = recurring_payment
          ? data?.data?.data?.url
          : data?.data?.toPayUrl;

        if (toPayUrl && validateURL(toPayUrl)) {
          window.location.href = toPayUrl;
        }

        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ["getPaymentIntent"] });
          setSubscribe(false);
        }, 30000);
      },
    }
  );

  useEffect(() => {
    if (recurring_payment && method !== "") {
      setSubscribe(true);
      queryClient.invalidateQueries({
        queryKey: ["getWebPay", payment_id, payMethod],
      });
    }
  }, []);

  const handlePayment = () => {
    // tobe removed
    if (payMethod.toLocaleLowerCase() === "airtime") {
      //phone.includes(customerPhone) &&
      const data: any = queryClient.getQueryData([
        "getUserSubscriptions",
        payment_id,
      ]);

      const planId = data?.data?.data?.subscription?.plan_id;
      const text =
        planId === "gbil_swmu_1d" ? "A" : planId === "gbil_swmu_1w" ? "B" : "";

      setSubscribe(true);
      queryClient.invalidateQueries({
        queryKey: ["getWebPay", payment_id, payMethod],
      });

      window.location.href = `sms:9107;?&body=${text}`;

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["getPaymentIntent"] });
      }, 30000);
    } else {
      setSubscribe(true);
      queryClient.invalidateQueries({
        queryKey: ["getWebPay", payment_id, payMethod],
      });
    }
  };

  useEffect(() => {
    if (
      isSuccess &&
      (payMethod?.includes("amharabank") || payMethod?.includes("zemenbank"))
    )
      setOpenDialog(true);
  }, [isSuccess, payMethod]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingButton
        loading={subscribe}
        loadingPosition="center"
        sx={{
          // mt: '1rem',
          p: "1rem",
          width: "100%",
          backgroundColor: "#FFAA00",
          borderRadius: "8px",
          ":hover": {
            backgroundColor: "#FFAA00",
          },
        }}
        variant="contained"
        size="large"
        disabled={!!payMethod ? false : true}
        onClick={handlePayment}
      >
        {!recurring_payment ? "Pay" : "Subscribe"}
      </LoadingButton>
    </Box>
  );
};

export default PaySubscribe;
