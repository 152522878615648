import React, { ReactNode, useMemo } from 'react';
//material ui
import { CssBaseline, Theme } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import componentsOverride from './overrides/';
import palette from './palette';
import shape from './shape';
import typography from './typography';
import GlobalStyles from './GlobalStyles';

interface Props {
  children?: ReactNode;
}
const ThemeConfig = ({ children }: Props) => {
  const themeOptions:any = useMemo(
    () => ({
      palette,
      shape,
      typography
    }),
    [],
  );
  const theme: Theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default ThemeConfig