import { Box, Divider, Typography } from '@mui/material'
import axios from 'axios';
import {FC, useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {  UserSubscriptions } from '../../utils/types';
import { PaymentContext } from '../PaymentStatus/PaymentStatus';

const Subscription= ({isSuccess, userSubscriptions }:{ isSuccess: boolean, userSubscriptions: UserSubscriptions}) => {

  return (
      <>
        { isSuccess &&  
          <Box>
            <Typography 
              sx = {{ color: "primary.lighter" }}
              variant="h5" 
            >
              Subscribe to {userSubscriptions.name}
            </Typography>
            <Typography 
              sx={{color: "common.black" }} 
              variant="h4" 
            > 
              STANDARD PREMIUM 
            </Typography>
            <Divider sx={{color: '#000',  m:{xs: 3, md: 4}, ml: {xs: 0}}}/>
            <Box 
              sx = {{
                mt: '1.5rem', 
                display: 'flex', 
                alignItems: 'center', 
                color: "common.black"
              }}
            >
              <Typography  variant="h3" sx={{mr: '9px' }}>{userSubscriptions.price} ETB</Typography> 
              <Typography variant="h5"> / {userSubscriptions.billing_scheme}</Typography>
            </Box>
          </Box>
        }
      </>
  )
}

export default Subscription