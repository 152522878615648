import { Box, Theme } from '@mui/material'
import { FC, useContext } from 'react'
import { PaymentContext } from '../PaymentStatus/PaymentStatus'

interface colorProps { 
    ownerState: {
      completed?: boolean,
      active?: boolean ,
      error?:boolean,
    },
    children: React.ReactNode,
    className?: string
}
  
const ColorlibStepIconRoot : FC<colorProps> = (props) => {

    return (
        <Box 
            sx={{
                backgroundColor: 'primary.light',
                zIndex: 1,
                color: '#fff',
                width: 50,
                height: 50,
                display: 'flex',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                ...(props.ownerState.active && {
                    backgroundColor: 'primary.light',
                }),
                ...(props.ownerState.completed && {
                    backgroundColor: 'primary.main',
                }),
                ...(props.ownerState.error && {
                    backgroundColor: 'error.main',
                }),
            }}
        >
            {
                props.children
            }
        </Box>
    )
}

export default ColorlibStepIconRoot