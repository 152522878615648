import { useEffect, useState } from "react"
import { getTimeoutConfig } from "../api/getTimeoutConfiguration"

interface TimeoutConfig {
    name: string,
    timeout: number,
    waiting_time: number
}

export const initalTimeout = {
    name: '', 
    timeout: 0, 
    waiting_time: 0
}

const useTimeoutConfig = ()=>{
    const [timeoutConfig, setTimeoutConfig] = useState<TimeoutConfig>(initalTimeout)

    useEffect(() => {
       const getTimeout = async ()=>{
           try {
            const resp = await getTimeoutConfig()
            if(resp !== undefined && resp !== null)
             setTimeoutConfig({name: resp?.name, timeout: resp?.timeout, waiting_time: resp?.wating_time})
           } catch (error) {
            // console.log(error)
           }
       }

       getTimeout() 
      }, [])

    return {timeoutConfig, setTimeoutConfig}
}

export default useTimeoutConfig