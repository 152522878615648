import { FC, SetStateAction, createContext, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ErrorResponse, PaymentIntents } from '../utils/types';
import PaymentStatus from '../components/PaymentStatus/PaymentStatus';
import { getStatus } from '../utils/status';
import { Loading, Error } from '../components/common';
import useTimeoutConfig from '../hooks/useTimeoutConfig';

let paymentIntents: PaymentIntents = {
  payment_id: '',
  amount: 0,
  recurring_payment: false,
  reason_failed: '',
  status_code: '',
  return_url: '',
  customerPhone: '',
  method: '',
  productName: '',
  branchEmail: '',
  branchPhone: '',
  selected_method_logo: '',
  setSelectedMethodLogo: function (value: SetStateAction<string>): void {},
};

export const CheckoutContext = createContext(paymentIntents);

const Checkout: FC = () => {
  const { timeoutConfig } = useTimeoutConfig();
  const { timeout, waiting_time } = timeoutConfig;

  const intervalMs = useRef(0);

  const [logo, setlogo] = useState<string>('');

  const { checkoutId } = useParams();

  const { isLoading, isError, data, error, isSuccess } = useQuery(
    ['getPaymentIntent', checkoutId, timeout, waiting_time],
    () => {
      return axios.get(`/payment_intents/${checkoutId}`);
    },
    {
      onError: (error) => {
        console.error(error);
        intervalMs.current = 0;
      },
      refetchInterval: (data) => {
        if (
          getStatus(data?.data?.data.status_code) === 'PAYMENT_PENDING' ||
          getStatus(data?.data?.data.status_code) === 'PAYMENT_SUCCESS'
        ) {
          if (intervalMs.current < waiting_time) {
            intervalMs.current += 1000;
            return timeout;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      enabled: !!checkoutId,
    }
  );

  if (data) {
    const {
      payment_id,
      amount,
      recurring_payment,
      status_code,
      reason_failed,
      method,
    } = data?.data?.data;
    paymentIntents = {
      payment_id,
      amount,
      recurring_payment,
      status_code,
      return_url:
        data?.data?.data?.payment_return_url ||
        data?.data?.data?.transaction?.product?.return_url,
      reason_failed,
      customerPhone: data?.data?.data?.customer?.phone_number,
      method: method?.toLocaleLowerCase().split(' ').join(''),
      productName: data?.data?.data?.transaction?.product?.name,
      branchEmail: data?.data?.data?.transaction?.product?.branches[0]?.email,
      branchPhone: data?.data?.data?.transaction?.product?.branches[0]?.branch_phone,
      selected_method_logo: logo,
      setSelectedMethodLogo: setlogo,
    };
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err)) {
      const newErr = err?.response?.data as ErrorResponse;
      return <Error {...newErr} />;
    }
  }

  return (
    <Box
      component="main"
      sx={{
        minHeight: '100vh',
        maxHeight: '100vh',
        minWidth: '100vw',
        maxWidth: '100vw',
      }}
    >
      {isSuccess && (
        <CheckoutContext.Provider value={paymentIntents}>
          <PaymentStatus />
        </CheckoutContext.Provider>
      )}
    </Box>
  );
};

export default Checkout;