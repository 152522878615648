import { Button, Grid, useMediaQuery } from "@mui/material";
import { FC, useContext, useState } from "react";
import { CheckoutContext } from "../../pages/Checkout";
import { getStatus } from "../../utils/status";
import { PaymentContext } from "./PaymentStatus";
import Message from "./Message";
import { validateURL } from "../../utils/validateURL";
import { LoadingMessage } from "../common";
import RequestOtp from "../PayWithBankAccount/RequestOtp";

const PaymentMessage: FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(true);
  const { paymentStatus, setActiveStep } = useContext(PaymentContext);
  const {
    status_code,
    return_url,
    method: payIntentMethod,
  } = useContext(CheckoutContext);

  const payStatus = getStatus(status_code);
  const matchesXs = useMediaQuery("(max-width:380px)");

  const handleClick = () => {
    if (payStatus === "PAYMENT_PENDING" && paymentStatus === "PAYMENT_FAILED") {
      setActiveStep(1);
    } else {
      // Go Back Home
      if (paymentStatus === "PAYMENT_FAILED") {
        setActiveStep(1);
      }
      // Go to sucess page
      if (
        (paymentStatus === "PAYMENT_SUCCESS" ||
          paymentStatus === "NOTIFICATION_SUCCESS") &&
        return_url !== null &&
        validateURL(return_url)
      ) {
        window.location.href = return_url.includes("app")
          ? return_url
          : return_url + "/success";
      }
      // Go to error page
      if (
        (paymentStatus === "PAYMENT_FAILED" ||
          paymentStatus === "NOTIFICATION_FAILED" ||
          paymentStatus === "PAYMENT_EXPIRED") &&
        return_url !== null &&
        validateURL(return_url)
      ) {
        window.location.href = return_url.includes("app")
          ? return_url
          : return_url + "/failed";
      }
    }
  };

  const checkPaymentonProcess = () => {
    if (
      (payStatus === "PAYMENT_PENDING" &&
        (paymentStatus !== "PAYMENT_FAILED" || status_code === "")) ||
      paymentStatus === "PAYMENT_PENDING"
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        direction: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100%",
      }}
    >
      <Grid item xs={12} sx={{ alignSelf: matchesXs ? "end" : "center" }}>
        {checkPaymentonProcess() ? (
          (payIntentMethod === "amharabank" ||
            payIntentMethod === "zemenbank") &&
          openDialog ? (
            <RequestOtp setOpenDialog={setOpenDialog} payIntentMethod={payIntentMethod}/>
          ) : (
            <LoadingMessage />
          )
        ) : (
          (payStatus !== "PAYMENT_INITILIZED" ||
            paymentStatus !== "PAYMENT_INITILIZED") && <Message />
        )}
      </Grid>
      <Grid item xs={12} sx={{ alignSelf: matchesXs ? "center" : "start" }}>
        <Button
          sx={{
            mt: "4rem",
            p: "1rem",
            borderRadius: "8px",
            width: { xs: "100%", sm: "20rem" },
            visibility: `${checkPaymentonProcess() ? "hidden" : "visible"}`,
            backgroundColor: "#FFAA00",
          }}
          variant="contained"
          size="large"
          disabled={checkPaymentonProcess()}
          onClick={handleClick}
        >
          {payStatus === "PAYMENT_PENDING" && paymentStatus === "PAYMENT_FAILED"
            ? "Retry Checkout"
            : "Go Back Home"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaymentMessage;
