import { FC, useContext } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Theme } from '@mui/material/styles';
import { PaymentContext } from '../PaymentStatus/PaymentStatus';

const ColorlibConnector : FC = () => {
    const { paymentStatus } = useContext(PaymentContext)
  
    return (
      <StepConnector 
        sx={{
          [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
          },
          [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
              backgroundColor: (theme : Theme) => 
                paymentStatus === "PAYMENT_SUCCESS" || 
                paymentStatus === ''                || 
                paymentStatus === "PAYMENT_INITILIZED"   || 
                paymentStatus ==='PAYMENT_PENDING' ? theme.palette.primary.light : theme.palette.error.main
            },
          },
          [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
              backgroundColor: 'primary.main',
            },
          },
          [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: (theme: Theme) =>
              theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
          },
        }}
      />
    )
  }

export default ColorlibConnector