import { FC, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import CustomPinInput from "./PinInput";
import { CheckoutContext } from "../../pages/Checkout";

interface OtpForm {
  setOpenDialog: (input: boolean) => void;
  payIntentMethod: string;
}

const RequestOtp: FC<OtpForm> = ({ setOpenDialog, payIntentMethod }) => {
  const { payment_id, customerPhone, selected_method_logo } =
    useContext(CheckoutContext);

  const [inputValue, setInputValue] = useState<string>("");

  const mutation = useMutation(
    (otpInput: { otp: string }) =>
      axios.post(`/${payIntentMethod}/${payment_id}/confirm`, otpInput),
    {
      onSuccess: () => {
        setOpenDialog(false);
      },
      onError: () => {
        setOpenDialog(false);
      },
    }
  );

  // HELPERS
  const onSubmit = (formData: any) => {
    return mutation.mutate(formData);
  };

  useEffect(() => {
    const userInput = { otp: inputValue };
    if (inputValue.length === 6) mutation.mutate(userInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
          mb: 2,
        }}
      >
        <Avatar
          variant="square"
          src={selected_method_logo}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 1.5,
            width: 65,
            height: 65,
            alignSelf: "center",
          }}
        />
      </Box>
      <Typography
        sx={{
          fontSize: 16,
          pt: 1,
          mb: 2,
        }}
      >
        Please fill the code sent to your phone number: {customerPhone} via SMS
      </Typography>
      <CustomPinInput handleOnSubmit={onSubmit} setInputValue={setInputValue} />
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          sx={{
            p: "1rem",
            width: "100%",
            backgroundColor: "#FFAA00",
            borderRadius: "8px",
            ":hover": {
              backgroundColor: "#FFAA00",
            },
          }}
          onClick={() => onSubmit({ otp: inputValue })}
          disabled={mutation.isLoading || inputValue?.length !== 6}
        >
          Continue
          {mutation.isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: "black",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Button>
      </Box>
    </Container>
  );
};
export default RequestOtp;
