import { Box, Divider, Theme, Typography } from '@mui/material'
import{ FC, useContext } from 'react'
import { CheckoutContext } from '../../pages/Checkout'
import { UserSubscriptions } from '../../utils/types'
import Subscription from './Subscription'

const PaymentDetails  = ({isSuccess, userSubscriptions }:{ isSuccess: boolean, userSubscriptions: UserSubscriptions}) => {

  const {payment_id, amount, recurring_payment, productName} = useContext(CheckoutContext)

  return (
  <Box 
    sx={{ 
      background: '#FAFAFA',
      border: "1px solid #E4E4E4",
      borderRadius: '7px',
      textAlign: 'left',
      pl: '1.5rem',
      height: '100%'
    }} 
  >
    <Typography 
      sx={{ m: '1.5rem 0rem', color: "primary.lighter"  }} 
      variant="h6" 
    >
      Payment ID : {payment_id}
    </Typography>
    {
      recurring_payment ? 
        (<Subscription isSuccess={isSuccess} userSubscriptions={userSubscriptions}/>) : 
        (<Box>
          <Typography 
            sx = {{ color: (theme: Theme) => theme.palette.common.black }}
            variant="h5" 
          >
            Pay to {productName}
          </Typography>
          <Divider sx={{color: '#000',  my:{xs: 1, md: 2}, ml: {xs: 0}}}/>
          <Typography 
            variant="h3" 
            sx={{ color: (theme: Theme) => theme.palette.common.black, mb: 1 }}
          > 
            {amount} ETB 
          </Typography>
        </Box>
        
         )
    }
  </Box>
  )
}

export default PaymentDetails