import React, { FC, useContext, useState } from "react";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import PaySubscribe from "../Checkout/PaySubscribe";
import { AccountForm } from "../../utils/types";
import { CheckoutContext } from "../../pages/Checkout";

const AccountInputForm: FC<AccountForm> = ({
  method,
  paymentMethodId,
  setMethod,
}) => {
  const { selected_method_logo } = useContext(CheckoutContext);

  const [account, setAccountNumber] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setAccountNumber(data?.account_number);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "common.black", textAlign: "center" }}
          >
            Pay with
          </Typography>
          <Avatar
            variant="square"
            src={selected_method_logo}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: 1.5,
              width: 55,
              height: 55,
              alignSelf: "center",
            }}
          />
        </Box>
        <TextField
          fullWidth
          {...register("account_number", { required: true })}
          label="Account number"
          error={!!errors.account_number}
          helperText={
            errors.account_number ? "Account number is required" : null
          }
          sx={{
            my: 2,
          }}
          onChange={(event) => setAccountNumber(event.target.value)}
        />
      </form>
      <PaySubscribe
        payMethod={method}
        accountNumber={account}
        paymentMethodId={paymentMethodId}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{
          mt: "1rem",
          p: "1rem",
          backgroundColor: "#e9e9e9",
          borderRadius: "8px",
          color: "#1c2526",
          ":hover": {
            backgroundColor: "#e9e9e9",
          },
        }}
        onClick={() => setMethod("")}
      >
        Back
      </Button>
    </React.Fragment>
  );
};
export default AccountInputForm;
