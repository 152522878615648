import { CssBaseline } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ThemeConfig from './theme'
import GlobalStyles from './theme/GlobalStyles'

ReactDOM.createRoot(document.getElementById('root')!).render(
    <ThemeConfig>
      <App />
    </ThemeConfig>
)
