import { FC } from 'react';
import { CircularProgress, Grid} from '@mui/material'

const Loading: FC = () => {
  return (
    <Grid container display='flex' direction='column' alignItems='center' justifyContent='center' 
      sx={{ height: '100%' }}
    > 
      <CircularProgress />
    </Grid>
  )
}

export default Loading